﻿/* Move down content because we have a fixed navbar that is 50px tall */
html, body {
    font-family: Verdana,"Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #1a1a1a;
}

h1, h2, h3 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
    color: #254689;

    &:hover {
        color: red;
        text-decoration: underline;
    }
}

h3.first {
    margin-top: 0;
}

.header-web {
    height: 159px;
    background-position: left top;
    background-repeat: no-repeat;

    .login {
        text-align: right;
        margin-top: 92px;
    }
}

.footer-web {
    height: 76px;

    footer {
        font-size: 11px;
        text-align: right;
        margin-top: 34px;
    }
}

.footer-web footer, .footer-web footer a {
    color: #777;
    text-decoration: none;
}

body {
    padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */


input[type="radio"] + label {
    font-weight: normal;
    margin-left: 6px;
}

#left-contact-outside {
    margin-left: -8px;
}

#left-contact {
    margin: 20px auto 0 auto;
    width: 163px;
    font-size: 11px;
    padding: 6px 6px;
    border: solid 1px #254689;
    border-radius: 5px;
    background: #f5f5f5;
    background: -moz-linear-gradient(top, #f5f5f5 0%, #ececec 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f5f5f5), color-stop(100%,#ececec));
    background: -webkit-linear-gradient(top, #f5f5f5 0%,#ececec 100%);
    background: -o-linear-gradient(top, #f5f5f5 0%,#ececec 100%);
    background: -ms-linear-gradient(top, #f5f5f5 0%,#ececec 100%);
    background: linear-gradient(to bottom, #f5f5f5 0%,#ececec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ececec',GradientType=0 );
}


.bluebox {
    background-color: #ECF1EF;
    border-top: solid 1px #254689;
    border-bottom: solid 1px #254689;
    padding: 9px;
    font-size: 13px;
    margin-top: 20px;

    &:first-child {
        margin-top: 0;
    }

    & h4 {
        margin-top: 0;
        font-size: 14px;
        color: #254689;
        font-weight: bold;
    }

    & ul {
        padding-left: 20px;
    }

    &.img {
        border: none;
        padding: 0;
        background-color: #fff;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }


    &.sitebadges {
        border: none;
        padding: 0;
        background-color: #fff;

        .sitebadge {
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: auto;
            margin-right: auto;

            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.white {
        border: none;
        padding: 0;
        background-color: #fff;
    }
}



.page-header {
    border-bottom-color: #254689;
    margin-top: 20px;

    h1 {
        color: #254689;
    }
}


.img-right {
    float: right;
    padding-left: 10px;
}

@media (min-width: 768px) {
    html, body {
        background: #7F7F7F;
    }

    .body-content {
        background: #fff url('/images/body-bkg.png') repeat-y;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.80);
        -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.80);
        -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.80);
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    /* sm */
    .header-web {
        background-image: url('/images/header-member-sm.png');
    }

    .members .header-web {
        background-image: url('/images/header-member-sm.png');
    }

    .footer-web {
        background: url('/images/footer-sm.png') no-repeat top left;
    }


    .members .footer-web {
        background-image: url('/images/footer-member-sm.png');
    }


    .members.body-content {
        background-image: none;
    }

    .body-content {
        background-position: -222px 0;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    /* md */

    .header-web {
        background-image: url('/images/header-member-md.png');
    }

    .members .header-web {
        background-image: url('/images/header-member-md.png');
    }

    .footer-web {
        background: url('/images/footer-md.png') no-repeat top left;
    }

    .members .footer-web {
        background-image: url('/images/footer-member-md.png');
    }

    .body-content {
        background-position: -166px 0;
    }
}

@media (min-width: 1200px) {
    /* lg */
    .header-web {
        background-image: url('/images/header-member-lg.png');
    }

    .members .header-web {
        background-image: url('/images/header-member-lg.png');
    }

    .footer-web {
        background: url('/images/footer-lg.png') no-repeat top left;
    }


    .members .footer-web {
        background-image: url('/images/footer-member-lg.png');
    }

    .body-content {
        background-position: -214px 0;
    }
}


@media (max-width: 767px) {
    /* xs */
    body {
        padding-top: 50px;
    }

    .header-mobile {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .header-mobile img {
        display: block;
        width: 100% \9;
        max-width: 100%;
        height: auto;
    }

    .footer-web {
        height: auto;

        footer {
            font-size: 11px;
            text-align: left;
            margin-top: 1em;
            padding-top: 1em;
            border-top: solid 1px #ccc;
        }
    }

    .img-right {
        float: none;
        display: block;
        margin-bottom: 10px;
    }
}




/* pricing table */
.pricetablefull .pricetable {
    width: 100%;
    margin-bottom: 20px;
}

.pricetable {
    margin: 0 auto 10px auto;

    td, th {
        padding: 4px 8px;
        border: solid 1px #fff;
    }

    th {
        background-color: #6495ED;
        color: white;
        font-weight: bold;
    }

    td {
        background-color: #eeeeee;
    }

    .register {
        width: 105px;
        padding: 4px 0;
        text-align: center;
    }

    .registerhome {
        width: 130px;
        padding: 4px 4px;
        text-align: center;
    }

    .price {
        text-align: center;
        width: 90px;
    }

    .name {
        font-weight: bold;
    }
}



.pricelistitem a {
    font-weight: bold;
}

/* breadcrumbs */
#breadcrumbs {
    font-size: 11px;
    color: #999;

    a {
        color: #999;
        text-decoration: none;
    }
}

/* cart */
.regsum {
    width: 100%;
    border-collapse: collapse;
}

.regsum .regsumdesc {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 8px;
    width: 75%;
}

.regsum .regsumprice {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    text-align: right;
    width: 25%;
}

.regsum .regsumdescfoot {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 8px;
    border-top: solid 1px black;
    font-weight: bold;
}

.regsum .regsumpricefoot {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 8px;
    text-align: right;
    border-top: solid 1px black;
    font-weight: bold;
}

.toptag {
    font-style: italic;
    font-weight: bold;
    margin-top: 0;
}

.left-img {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-right: 5px;
    margin-left: -5px;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
}


/* home page*/


#logobox {
    margin: 1em auto;
    padding: 1em;
    border: solid 3px #eee;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;

    h3 {
        text-align: center;
        margin-top: 0;
    }

    .logoitem {
        margin-bottom: 10px;
    }

    .logoitem img {
        margin-left: auto;
        margin-right: auto;
    }


    .readmore {
        text-align: center;
    }
}


.blog-pager {
    display: block;
    text-align: center;
}

.blog-pager li {
    cursor: pointer;
    display: inline-block;
}

.blog-pager li.active {
    font-weight: bold;
}

.blog-pager li + li:before {
    padding-left: 8px;
    padding-right: 8px;
    content: "\00a0";
}

.blog-pager li.blog-pager-number + li.blog-pager-number:before {
    content: " - ";
}

.blog-byline {
    font-weight: bold;
}

.blog-categories {
    font-size: smaller;
}

.blog-tags {
    font-size: smaller;
}

.blog-share {
    margin-top: 10px;
}
