﻿@use "sass:color";

.btnsite {
    $btn-color: #8a0c06;
    $btn-topgradient: #cd9694;
    $btn-color-hover: color.adjust($btn-color, $lightness: 10%);
    $btn-topgradient-hover: color.adjust($btn-topgradient, $lightness: 10%);
    $btn-color-blue: #355799;
    $btn-topgradient-blue: #8da2cb;
    $btn-color-hover-blue: color.adjust($btn-color-blue, $lightness: 10%);
    $btn-topgradient-hover-blue: color.adjust($btn-topgradient-blue, $lightness: 10%);

    appearance: none;
    background-color: $btn-color;
    background-image: linear-gradient($btn-topgradient, $btn-color);
    border: 1px solid $btn-color;
    border-radius: 4px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    list-style: none;
    padding: 4px 16px 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
    text-decoration: none;

    &:hover {
        color: #ffffff;
        background-color: $btn-color-hover;
        background-image: linear-gradient($btn-topgradient-hover, $btn-color-hover);
        transition-duration: 0.1s;
        text-decoration: none;
    }

    &:disabled {
        background-color: #FAFBFC;
        border-color: rgba(27, 31, 35, 0.15);
        color: #959DA5;
        cursor: default;
    }

    &:active {
        box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
        transition: none 0s;
        color: #ffffff;
    }

    &:focus {
        outline: 1px transparent;
    }

    &:before {
        display: none;
    }

    &:-webkit-details-marker {
        display: none;
    }
    // Fits in the header
    &.short {
        padding-top: 2px;
        padding-bottom: 4px;
    }

    &.block {
        padding-left:inherit;
        padding-right: inherit;
        display:block;
        text-align:center;
    }

    &.blue {
        background-color: $btn-color-blue;
        background-image: linear-gradient($btn-topgradient-blue, $btn-color-blue);
        border-color: $btn-color-blue;

        &:hover {
            color: #ffffff;
            background-color: $btn-color-hover-blue;
            background-image: linear-gradient($btn-topgradient-hover-blue, $btn-color-hover-blue);
        }
    }
}
