﻿@use "sass:color";

$navcolor-bkg: #0b486b;
$navcolor-text: #ecf0f1;

$navbar-blue-bg: $navcolor-bkg;
$navbar-blue-border: color.adjust($navcolor-text, $lightness: -7%);
$navbar-blue-brand-color: $navcolor-text;
$navbar-blue-brand-hover-color: $navcolor-text;
$navbar-blue-brand-hover-bg: $navcolor-bkg;
$navbar-blue-color: $navcolor-text;
$navbar-blue-link-color: $navcolor-text;
$navbar-blue-link-hover-color: $navcolor-text;
$navbar-blue-link-hover-bg: color.adjust($navcolor-bkg, $lightness: -7%);
$navbar-blue-link-active-color: $navcolor-text;
$navbar-blue-link-active-bg: color.adjust($navcolor-bkg, $lightness: -7%);
$navbar-blue-link-disabled-color: color.adjust($navcolor-text, $lightness: -7%);
$navbar-blue-link-disabled-bg: $navcolor-bkg;
$navbar-blue-toggle-border-color: $navcolor-text;
$navbar-blue-toggle-hover-bg: $navcolor-bkg;
$navbar-blue-toggle-icon-bar-bg: $navcolor-text;

.navbar-blue {
    background-color: $navbar-blue-bg;
    border-color: $navbar-blue-border;
    margin-bottom: 10px;

    .navbar-brand {
        color: $navbar-blue-brand-color;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0;

        &:hover,
        &:focus {
            color: $navbar-blue-brand-hover-color;
            background-color: $navbar-blue-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-blue-color;
    }

    .navbar-nav {
        > li > a {
            color: $navbar-blue-link-color;

            &:hover,
            &:focus {
                color: $navbar-blue-link-hover-color;
                background-color: $navbar-blue-link-hover-bg;
            }
        }

        > .active > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-blue-link-active-color;
                background-color: $navbar-blue-link-active-bg;
            }
        }

        > .disabled > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-blue-link-disabled-color;
                background-color: $navbar-blue-link-disabled-bg;
            }
        }
        // Dropdowns
        > .open > a {
            &,
            &:hover,
            &:focus {
                color: $navbar-blue-link-active-color;
                background-color: $navbar-blue-link-active-bg;
            }
        }

        @media (max-width: 767px) {
            // Dropdowns get custom display
            .open .dropdown-menu {
                > .dropdown-header {
                    border-color: $navbar-blue-border;
                }

                .divider {
                    background-color: $navbar-blue-border;
                }

                > li > a {
                    color: $navbar-blue-link-color;

                    &:hover,
                    &:focus {
                        color: $navbar-blue-link-hover-color;
                        background-color: $navbar-blue-link-hover-bg;
                    }
                }

                > .active > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-blue-link-active-color;
                        background-color: $navbar-blue-link-active-bg;
                    }
                }

                > .disabled > a {
                    &,
                    &:hover,
                    &:focus {
                        color: $navbar-blue-link-disabled-color;
                        background-color: $navbar-blue-link-disabled-bg;
                    }
                }
            }
        }
    }
    // Darken the responsive nav toggle
    .navbar-toggle {
        border-color: $navbar-blue-toggle-border-color;

        &:hover,
        &:focus {
            background-color: $navbar-blue-toggle-hover-bg;
        }

        .icon-bar {
            background-color: $navbar-blue-toggle-icon-bar-bg;
        }
    }

    .navbar-collapse,
    .navbar-form {
        border-color: color.adjust($navbar-blue-bg, $lightness: -7%);
    }

    .navbar-link {
        color: $navbar-blue-link-color;

        &:hover {
            color: $navbar-blue-link-hover-color;
        }
    }

    .btn-link {
        color: $navbar-blue-link-color;

        &:hover,
        &:focus {
            color: $navbar-blue-link-hover-color;
        }

        &[disabled],
        fieldset[disabled] & {
            &:hover,
            &:focus {
                color: $navbar-blue-link-disabled-color;
            }
        }
    }
}
