﻿/* Superfish menu */
/*** ESSENTIAL STYLES ***/
.menu-container {
    margin-left:-10px;
    margin-top:20px;
}
.sf-menu .title {
    text-align:center;
    padding-top:2px;
    padding-bottom:2px;
    color:#8B1A1A;
    background-color:#e0e0e0 !important;
}
.sf-menu, .sf-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}
.sf-menu li {
    position: relative;
}
.sf-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
}
.sf-menu > li {
    float: left;
}
.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
    display: block;
}

.sf-menu a {
    display: block;
    position: relative;
}
.sf-menu ul ul {
    top: 0;
    left: 100%;
}


/*** DEMO SKIN ***/
.sf-menu {
    float: left;
    margin: 0;
    padding:0;
    font: bold 12px tahoma,arial;
}
.sf-menu > li
{
    background: #f5f5f5; /* Old browsers */
    background: -moz-linear-gradient(top,  #f5f5f5 0%, #ececec 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f5f5f5), color-stop(100%,#ececec)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #f5f5f5 0%,#ececec 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #f5f5f5 0%,#ececec 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #f5f5f5 0%,#ececec 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #f5f5f5 0%,#ececec 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ececec',GradientType=0 ); /* IE6-9 */
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: relative;
    border-top: solid 1px #bababa !important;
    border-bottom: solid 1px #fff !important;
}

.sf-menu > li > a
{
    display: block;
    height: 100% !important;
    font: bold 12px tahoma,arial;
    color: #6A6A6A;
    padding: 3px 0 3px 11px;
    text-decoration: none;
    border:none !important;
    border-left:solid 5px #505050 !important;
}

.sf-menu > li > a:hover
{
    background: #e1e1e1; /* Old browsers */
    background: -moz-linear-gradient(top,  #e1e1e1 0%, #d8d8d8 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e1e1e1), color-stop(100%,#d8d8d8)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #e1e1e1 0%,#d8d8d8 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #e1e1e1 0%,#d8d8d8 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #e1e1e1 0%,#d8d8d8 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #e1e1e1 0%,#d8d8d8 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#d8d8d8',GradientType=0 ); /* IE6-9 */
    border-left:solid 8px #505050 !important;
    padding-left:8px;
}
.sf-menu ul {
    box-shadow: 2px 2px 6px rgba(0,0,0,.2);
    min-width: 12em; /* allow long menu items to determine submenu width */
    *width: 12em; /* no auto sub width for IE7, see white-space comment below */
}
.sf-menu a {
    border-left: 1px solid #fff;
    border-top: 1px solid #dFeEFF; /* fallback colour must use full shorthand */
    border-top: 1px solid rgba(255,255,255,.5);
    padding: 6px 12px;
    text-decoration: none;
    zoom: 1; /* IE7 */
}
.sf-menu a {
    color: #6A6A6A;
}
.sf-menu li {
    white-space: nowrap; /* no need for Supersubs plugin */
    *white-space: normal; /* ...unless you support IE7 (let it wrap) */
    -webkit-transition: background .2s;
    transition: background .2s;
}
.sf-menu ul li,.sf-menu ul ul li {
    background-color: #efefef;
}
.sf-menu li:hover,
.sf-menu li.sfHover {
    background: #CFDEFF;
    /* only transition out, not in */
    -webkit-transition: none;
    transition: none;
}

/*** arrows (for all except IE7) **/
.sf-arrows .sf-with-ul {
    padding-right: 2.5em;
    *padding-right: 1em; /* no CSS arrows for IE7 (lack pseudo-elements) */
}
/* styling for both css and generated arrows */
.sf-arrows .sf-with-ul:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;
    margin-top: -3px;
    height: 0;
    width: 0;
    /* order of following 3 rules important for fallbacks to work */
    border: 5px solid transparent;
    border-top-color: #cccccc; /* edit this to suit design (no rgba in IE8) */
    border-top-color: rgba(0,0,0,.5);
}
.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
    border-top-color: white; /* IE8 fallback colour */
}
/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
    margin-top: -5px;
    margin-right: -3px;
    border-color: transparent;
    border-left-color: #cccccc; /* edit this to suit design (no rgba in IE8) */
    border-left-color: rgba(0,0,0,.5);
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
    border-left-color: white;
}
/* adding sf-vertical class in addition to sf-menu creates a vertical menu */
/* eg. <ul class="sf-menu sf-vertical"> ... */
.sf-vertical {
    width: 100%;
  /* If you want the width of the closed menu to expand to its
  widest top-level menu item (like its "Supersubs" submenus do),
  replace the width rule above with the following two rules. */

  /*
  min-width: 12em;
  *width: 12em;
  */
}
.sf-vertical ul {
    left: 100%;
    top: 0;
}
.sf-vertical > li {
  float: none;
}
.sf-vertical li {
  width: 100%;
}

/*** alter arrow directions ***/
.sf-vertical.sf-arrows > li > .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #cccccc; /* edit this to suit design (no rgba in IE8) */
  border-left-color: rgba(0,0,0,.5);
}
.sf-vertical.sf-arrows li > .sf-with-ul:focus:after,
.sf-vertical.sf-arrows li:hover > .sf-with-ul:after,
.sf-vertical.sf-arrows .sfHover > .sf-with-ul:after {
  border-left-color: white;
}